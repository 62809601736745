const theme = {
  mm: {
    // primary: '#6DF4E7',
    primary: "rgb(0, 0, 160)",
    primaryLight: "rgb(0, 158, 235)",
    altOne: "rgb(230, 160, 0)",
    altTwo: "rgb(227, 0, 115)",
    debrand: "#8A91A0",

    textGrey: "rgb(60, 60, 60)",
    textError: "red",

    snapchat: "#FFFC00",
    instagram: "#C13584",
    facebook: "#0078FF",

    white: "#fff",
    lightGrey: "#f4f4f4",
    darkGrey: "#666666",
    black: "#000",
    blackAlpha: "rgba(0, 0, 0, .65)",

    errorInputBG: "rgba(255, 0, 0, .75)"
  },
  pallet: {
    primary: "#FAA61A",
    darkPurple: "#211549",
    sidebarBackground: "#A3057F",
    

    funGreen: "#00db93",
    white: "#ffffff",
    blue: "#21a5e3",
    pink: "#ffc0cb",
    black: "#202020",
    lightGrey: "#f1f1f1",
    grey: "#afafaf",

    defaultGradient:
      "linear-gradient(135deg, #4b5acb 0%,#c134a2 50%,#fcbe45 100%)"
  },
  fonts: {
    $fontFamily: "Montserrat, sans-serif",
    $fontWeightRegular: 400,
    $fontWeightMedium: 500,
    $fontWeightBold: 700,
    $fontWeightExtraBold: 800
  },
  breakPoints: {
    small: "768px",
    medium: "992px",
    large: "1200px"
  },
  animations: {
    default: "all .15s ease-in-out"
  },
  utils: {
    borderRadius: "5px"
  }
};

export type ThemeType = typeof theme;
export default theme;
