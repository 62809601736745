import React from "react";
import styled from "styled-components";
import { motion, AnimateSharedLayout, transform } from "framer-motion";
import {
  useResponses,
  useQuestions,
  useGrid,
  useBuckets,
  useOptions,
  useOverlays
} from "hooks";
import hexToRgba from "hex-to-rgba";
import {
  getPrimaryFormatFromResponses,
  getQuestionsFromResponses
} from "utils/facelift";
import { Response } from "types/facelift";
import QuestionBox from "./QuestionBox";
import theme from "assets/styles/theme";

function GridSidebar() {
  const responses = useResponses((s) => s.responses);
  const gridResponses = useResponses((s) => s.gridResponses);
  const currentQuestion = useQuestions((s) => s.currentQuestion);
  const updateSearching = useGrid((s) => s.updateSearching);
  const updateExpandedIndex = useGrid((s) => s.updateExpandedIndex);
  const searchTerm = useGrid((s) => s.searchTerm);
  const updateCurrentQuestion = useQuestions((s) => s.updateCurrentQuestion);
  const selectedBucket = useBuckets((s) => s.selectedBucket);
  const showingSidebarContent = useOverlays((s) => s.showingSidebarContent);
  const sidebarContentType = useOverlays((s) => s.sidebarContentType);
  const selectedUser = useGrid((s) => s.selectedUser);

  const { options, selectedFilters } = useOptions();

  const questions = getQuestionsFromResponses(responses);

  function handleQuestionChange(questionNo: string) {
    updateSearching(true);
    updateExpandedIndex(null);

    if (Number(questionNo) === currentQuestion) {
      updateCurrentQuestion(null, true);
    } else {
      updateCurrentQuestion(Number(questionNo), true);
    }
  }

  const entries = Object.entries(selectedFilters)
    .filter((item) => item[1].length > 0)
    .map((item) => [
      options.find((option) => option.displayName === item[0])?.columnName,
      item[1]
    ]);

  const optionsToCheck = Object.fromEntries(entries);

  function containsSearchWord(response: Response) {
    if (searchTerm.length === 0) return true;

    return response.text.toLowerCase().includes(searchTerm?.toLowerCase());
  }

  function passesFilters(response: Response) {
    for (let option in optionsToCheck) {
      const responseOption = response[option as keyof Response];
      const stateOption = optionsToCheck[option];

      if (!stateOption.includes(responseOption)) {
        return false;
      }
    }
    return true;
  }

  const filteredResponses = gridResponses
    .filter(containsSearchWord)
    .filter(passesFilters);

  const questionNumbers = Object.keys(questions);
  const questionCount = questionNumbers.map(
    (questionNumber) =>
      filteredResponses.filter((resp) => resp.question_no === questionNumber)
        .length
  );
  const min = Math.min(...questionCount);
  const max = Math.max(...questionCount);

  const background = transform([min, max], ["#94a2d4", "#635ec0"]);
  const scale = transform([min, max], [0.9, 1.3]);

  const color =
    selectedBucket.id !== undefined ? selectedBucket.color : "#635ec0";

  const rgbaColor = hexToRgba(color, "0.75");

  const shouldChangeText =
    sidebarContentType === "filters" && showingSidebarContent;

  if (selectedBucket.id !== undefined) {
    return null;
  }

  if (selectedUser !== null) {
    return null;
  }

  return (
    <Wrapper>
      <VerticalText style={{ background: rgbaColor }}>
        {shouldChangeText ? "Number of responses" : "Questions"}
      </VerticalText>
      <AnimateSharedLayout>
        <QuestionNumberBlock>
          {questionNumbers.map((questionNumber) => {
            const isCurrent = Number(questionNumber) === currentQuestion;

            function isCurrentQuestion(response: Response) {
              return response.question_no === questionNumber;
            }

            const currentQuestionResponses = filteredResponses.filter(
              isCurrentQuestion
            );

            const primaryFormat = getPrimaryFormatFromResponses(
              currentQuestionResponses
            );

            const question = currentQuestionResponses[0]?.question;

            const amount = currentQuestionResponses.length;

            const questionProps = {
              questionNumber,
              question,
              handleQuestionChange,
              amount,
              background,
              scale,
              isCurrent,
              primaryFormat
            };

            return <QuestionBox key={questionNumber} {...questionProps} />;
          })}

          {/* <QuestionLine /> */}
        </QuestionNumberBlock>
      </AnimateSharedLayout>
    </Wrapper>
  );
}

export default GridSidebar;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
`;

const VerticalText = styled(motion.div)`
  /* margin-top: 5rem; */

  text-align: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(-180deg);
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  background: ${theme.mm.primary};
  /* border-radius: 5px; */

  padding: 2rem 0.5rem;
`;

const QuestionWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const QuestionNumberBlock = styled(motion.div)`
  width: 100px;
  height: 100%;
  padding: 1rem;
  display: grid;
  grid-auto-flow: row dense;
  grid-auto-rows: 50px;
  grid-gap: 1rem;
  overflow: auto;
  align-items: flex-start;
  justify-content: center;
  scroll-padding: 1rem;
  scroll-snap-type: y mandatory;
  position: relative;
`;

const QuestionLine = styled.div`
  background: #635ec0;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 2px;
  height: 2000px;
  z-index: 0;
`;

const ActiveQuestionNumber = styled.div`
  color: #635ec0;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  font-family: "Century Gothic", "Open Sans", sans-serif;
  padding: 0.5rem 0;
`;
