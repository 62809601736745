import * as React from "react";
import { Formik, Field, FormikActions } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Helmet } from "react-helmet";
import qs from "query-string";

import FormInput from "../../components/ui/FormInput";
import FormErrorText from "../../components/ui/FormErrorText";
import Logo from "../../assets/images/sympler.png";
import RegisterShadowSrc from "../../assets/images/register-shadow.png";
import MagicMirrorSrc from "../../assets/images/mm-mirror.png";
import {
  AuthContainer,
  Title,
  SymplerLogo,
  Form,
  SubmitButton,
  AuthContainerShadow,
  MagicMirrorGuy
} from "../../assets/styles/authStyles";
import { SuccessMessage } from "./styled";

interface IResetPasswordState {
  submitSuccess: boolean;
}

interface IFormValues {
  password: string;
  password_confirmation: string;
}

class ResetPassword extends React.Component<{}, IResetPasswordState> {
  code: string | null = null;

  constructor(props: IResetPasswordState) {
    super(props);

    this.state = {
      submitSuccess: false
    };
  }

  formValidation = Yup.object().shape({
    password: Yup.string().required("A password is required"),
    password_confirmation: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("password"), null], "Passwords must match")
  });

  initialFormValues = { password: "", password_confirmation: "" };

  componentDidMount() {
    const parsed = qs.parse(window.location.search);

    if (Object.keys(parsed).length === 0 || !parsed["reset-code"]) {
      // No query string found
      window.location.href = "/";
    }

    const code: string = parsed!["reset-code"]! as string;

    this.code = code;
  }

  async handleSubmit(values: IFormValues, actions: FormikActions<IFormValues>) {
    if (!this.code) {
      window.location.href = "/";
    }

    const data = {
      password: values.password,
      password_confirmation: values.password_confirmation,
      code: this.code
    };

    try {
      await axios.post<any>(`auth/validateforgotpassword`, data);

      actions.setStatus("");
      this.setState({ submitSuccess: true });
    } catch (error) {
      actions.setStatus((error as any).errorMessage);
      actions.setSubmitting(false);
      this.setState({ submitSuccess: false });
    }
  }

  renderForm() {
    return (
      <React.Fragment>
        <AuthContainer>
          <SymplerLogo src={Logo} alt="Sympler Logo" />
          <Formik
            initialValues={this.initialFormValues}
            onSubmit={(values, actions) => this.handleSubmit(values, actions)}
            validationSchema={this.formValidation}
          >
            {(props) => {
              const { handleSubmit, isSubmitting, status } = props;

              return (
                <Form onSubmit={handleSubmit}>
                  <Title>Set New Password</Title>

                  {status && <FormErrorText text={status} />}

                  <Field
                    name="password"
                    type="password"
                    placeholder="Password"
                    component={FormInput}
                  />

                  <Field
                    name="password_confirmation"
                    type="password"
                    placeholder="Confirm Password"
                    component={FormInput}
                  />

                  <SubmitButton
                    type="submit"
                    text="Submit"
                    loadingText="loading"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  />
                </Form>
              );
            }}
          </Formik>
          <AuthContainerShadow src={RegisterShadowSrc} alt="Drop shadow" />
        </AuthContainer>
        <MagicMirrorGuy src={MagicMirrorSrc} alt="Sympler" />
      </React.Fragment>
    );
  }

  renderSuccess() {
    return (
      <React.Fragment>
        <AuthContainer>
          {/* <MarsWrigleyLogo src={MarsWriglySrc} alt="Mars Wrigley Logo" /> */}
          <Form>
            <SuccessMessage>Your password has been reset</SuccessMessage>
            <SubmitButton
              type="submit"
              text="Login"
              onClick={() => {
                window.location.href = "/";
              }}
            />
          </Form>
          <AuthContainerShadow src={RegisterShadowSrc} alt="Drop shadow" />
        </AuthContainer>
        <MagicMirrorGuy src={MagicMirrorSrc} alt="Sympler" />
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Sympler - Reset Password</title>
        </Helmet>
        {this.state.submitSuccess ? this.renderSuccess() : this.renderForm()}
      </React.Fragment>
    );
  }
}

export default ResetPassword;
