import React, { useContext } from "react";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps
} from "react-router-dom";

// import { IAuthContext } from "../../types/auth";
// import { IRoutesContext, IRoute } from "../../types/routes";
import RoutesContext from "../../context/RoutesContext";

import { Wrapper } from "./styled";
import Sidebar from "../Sidebar";

import useApp from "context/AppContext";

interface PrivateRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

type RenderComponent = (props: RouteComponentProps<any>) => React.ReactNode;

// class PrivateRoute extends Route<PrivateRouteProps> {
//   buildRenderComponent(routes: IRoute[], token?: string) {
//     const { component: Component, ...rest }: PrivateRouteProps = this.props;
//     const renderComponent: RenderComponent = (props) => {
//       if (token) {
//         let hideSidebar = false;

//         if (`${process.env.REACT_APP_FIRST_ROUTE}` === "/magic-mirror") {
//           hideSidebar = true;
//         }

//         return (
//           <Wrapper hideSidebar={hideSidebar}>
//             <Sidebar routes={routes} hideSidebar={hideSidebar} />
//             <Component {...props} />
//           </Wrapper>
//         );
//       } else {
//         return <Redirect to="login" />;
//       }
//     };

//     return <Route {...rest} render={renderComponent} />;
//   }

//   render() {
//     return (
//       <AuthContext.Consumer>
//         {(authContext: IAuthContext) => (
//           <RoutesContext.Consumer>
//             {(routesContext: IRoutesContext) => {
//               return this.buildRenderComponent(
//                 routesContext.routes,
//                 authContext.token
//               );
//             }}
//           </RoutesContext.Consumer>
//         )}
//       </AuthContext.Consumer>
//     );
//   }
// }

// export default PrivateRoute;

export const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const routes = useContext(RoutesContext);
  const { appCtx } = useApp();
  if (
    !appCtx.token &&
    !props.path?.includes("resetpassword") &&
    !props.path?.includes("forgot-password")
  )
    return <Redirect to="/login" />;

  const { component: Component, ...rest }: PrivateRouteProps = props;

  const renderComponent: RenderComponent = (props) => {
    let hideSidebar = false;

    if (`${process.env.REACT_APP_FIRST_ROUTE}` === "/magic-mirror") {
      hideSidebar = true;
    }

    return (
      <Wrapper hideSidebar={hideSidebar}>
        <Sidebar routes={routes.routes} hideSidebar={hideSidebar} />
        <Component {...props} />
      </Wrapper>
    );
  };

  return <Route {...rest} render={renderComponent} />;
};
export default PrivateRoute;
